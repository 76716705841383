<script setup lang="ts">
import type {FlightSearchForm} from "~/types/flight/search/FlightSearchForm";
import {PassengerTypeCount} from "~/utils/flight/passenger/PassengerTypeCount";
import type {PassengerRule} from "~/types/flight/passenger/PassengerRule";
import {MakePassenger, typeByCode} from "~/utils/flight/passenger/MakePassenger";

const props = defineProps<{
    state: FlightSearchForm
    showPassengersOptions: boolean,
    hasRoute?: boolean
}>()

const emit = defineEmits(['update:showPassengersOptions'])

const passengerCounts = computed(() => PassengerTypeCount(props.state.passengers))
const passengerText = computed(() => passengerCounts.value.text)

const handlePassenger = (params: { passenger: PassengerRule; action: 'add' | 'remove' }) => {
    if (params.action === 'add') {
        props.state.passengers.push(MakePassenger(typeByCode(params.passenger.type)))
    } else {
        const index = props.state.passengers.findIndex(p => p.type.code === params.passenger.type)
        if (index !== -1) props.state.passengers.splice(index, 1)
    }
}

</script>

<template>
    <div class="w-full flex gap-4 col-span-2 relative">
        <u-form-field
            name="passengers"
            :ui="{root: hasRoute ? 'w-full bg-transparent border border-white rounded-lg' : 'w-full bg-transparent border border-primary-900 rounded-lg', error: 'absolute text-xs'}"
        >
            <u-input
                v-model="passengerText"
                type="text"
                @focusin="emit('update:showPassengersOptions', true)"
                placeholder="مسافران"
                color="gray"
                variant="none"
                leading-icon="i-mdi-seat-passenger"
                :ui="{base: `${hasRoute ? 'text-white' : ''}` ,root: `${hasRoute ? 'text-white' : ''} w-full py-1 text-center  border-0 outline-none ring-0 focus:border-0 focus:outline-none focus:ring-0 bg-transparent`}"
                autocomplete="false"
                readonly
            />

            <lazy-flight-search-passenger-information-component
                :passengers="state.passengers"
                :open="showPassengersOptions"
                @close="(val: boolean) => emit('update:showPassengersOptions', val)"
                @handle-passenger="handlePassenger"
            />

            <template #error="{ error }">
                {{ error }}
            </template>
        </u-form-field>
    </div>
</template>

